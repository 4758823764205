import React, { useState, useEffect } from "react";
import introPermissionImage from "../../assets/introPermissionImage.png";
import Apps from "../../assets/Apps.svg";
import Bell from "../../assets/Bell.svg";
import Layout from "../../components/Layout";
import CtaButton from "../../components/CtaButton";
import { Switch } from "@headlessui/react";
import { sendAmplitudeData } from "../../utils/amplitude";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ToggleButton = ({ enabled, onChange, disabled = false }) => {
  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      disabled={disabled}
      className={classNames(
        enabled ? "bg-[#32D583]" : "bg-gray-200",
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
        "relative inline-flex h-8 w-14 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-6" : "translate-x-0",
          "pointer-events-none inline-block h-7 w-7 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
};

const Permissions = () => {
  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [installEnabled, setInstallEnabled] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Check if the app is already installed
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setInstallEnabled(true);
      setIsInstallable(false);
    }

    // Check notification permission status
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        setNotificationsEnabled(true);
      } else {
        // Request notification permission
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            setNotificationsEnabled(true);
            sendAmplitudeData("PUSH_NOTIFICATIONS_ENABLED");
          }
        });
      }
    }

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallToggle = async () => {
    if (!installEnabled && deferredPrompt) {
      try {
        // Trigger the installation prompt
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === "accepted") {
          setInstallEnabled(true);
          sendAmplitudeData("INSTALL_APP");
        }
      } catch (error) {
        console.error("Error during app installation:", error);
      } finally {
        setDeferredPrompt(null); // Reset the prompt after use
        setIsInstallable(false); // Disable the install toggle after successful install
      }
    } else if (installEnabled) {
      alert("App is already installed.");
    } else {
      alert("App cannot be installed on this device.");
    }
  };

  const handleNotificationToggle = async () => {
    if (!notificationsEnabled) {
      if ("Notification" in window) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          setNotificationsEnabled(true);
          sendAmplitudeData("PUSH_NOTIFICATIONS_ENABLED");
        }
      } else {
        console.log("Notifications not supported in this browser");
        alert("Notifications are not supported in this browser.");
      }
    } else {
      alert("To disable notifications, please use your browser settings.");
    }
  };

  return (
    <>
      <div className="relative">
        <img src={introPermissionImage} alt="permissions" />
      </div>

      <Layout>
        <div className="flex items-start gap-x-4 mt-6">
          <div className="bg-base_white rounded-[12px] shadow-permission_icon p-2 flex items-center justify-center">
            <img
              src={Apps}
              alt="download"
              className="min-h-[32px] min-w-[32px]"
            />
          </div>

          <div>
            <h3 className="heading-small text-light_bg_primary">
              Install app on your device
            </h3>
            <p className="text-sm-regular mt-1 text-light_bg_secondary">
              Find the app easily whenever you want on your homescreen
            </p>
          </div>
          <div>
            <ToggleButton
              enabled={installEnabled}
              onChange={handleInstallToggle}
              disabled={!isInstallable}
            />
          </div>
        </div>
        <hr className="text-[#E5E7EB] my-4" />
        <div className="flex items-start gap-x-4 mt-6">
          <div className="bg-base_white rounded-[12px] shadow-permission_icon flex items-center justify-center p-2">
            <img
              src={Bell}
              alt="download"
              className="min-w-[32px] min-h-[28px]"
            />
          </div>

          <div>
            <h3 className="heading-small text-light_bg_primary">
              Notifications
            </h3>
            <p className="text-sm-regular mt-1 text-light_bg_secondary">
              Get timely reminders to scan on Whatsapp/SMS
            </p>
          </div>
          <div>
            <ToggleButton
              enabled={notificationsEnabled}
              onChange={handleNotificationToggle}
            />
          </div>
        </div>
        <div className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4">
          <CtaButton ButtonText="Continue" LinkTo="/home" />
        </div>
      </Layout>
    </>
  );
};

export default Permissions;
