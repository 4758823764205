import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "../../src/assets/HomeIcon.svg";
import HomeIconSelected from "../../src/assets/HomeIconSelected.svg";
import ScanIcon from "../../src/assets/ScanIcon.svg";
import ResultsIcon from "../../src/assets/ResultsIcon.svg";
import ResultsIconSelected from "../../src/assets/ResultsIconSelected.svg";
import { sendAmplitudeData } from "../utils/amplitude"; // Import Amplitude utility

const BottomNav = () => {
  const location = useLocation();
  const { pathname } = location;

  const isHome = pathname.endsWith("/home") || pathname === "/home";
  const isResults = pathname.endsWith("/results") || pathname === "/results";

  // Log "Scan button" Amplitude click event
  const handleScanButtonClick = () => {
    sendAmplitudeData("TAP_DAILY_SCAN_BUTTON");
  };

  const [currentDay, setCurrentDay] = useState(0);

  useEffect(() => {
    let storedDay = localStorage.getItem("Day");
    if (!storedDay || storedDay < 1) {
      storedDay = 0;
    }
    setCurrentDay(storedDay);
  }, []);

  const eventName = `TAP_RESULT_BUTTON_${currentDay}`;

  // Log "Results tab" Amplitude click event
  const handleResultsTabClick = () => {
    sendAmplitudeData(eventName);
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-[1px] z-50 border-[#F3F4F6] ">
      <div className="flex justify-evenly items-center h-[73px]">
        <Link to="/home" className="flex flex-col items-center">
          <img src={isHome ? HomeIconSelected : HomeIcon} alt="Home" />
          <span
            className={`text-sm-regular text-light_bg_primary ${
              isResults ? "opacity-45" : "opacity-85"
            }`}
          >
            Home
          </span>
        </Link>

        {/* Scan Button */}
        <Link
          to="/daily-health-scan"
          className="flex justify-center items-center"
          onClick={handleScanButtonClick} // Attach event handler for Scan button
        >
          <button className="flex gap-x-2 bg-primary_100 py-[10px] text-md-regular text-primary_500 px-4 rounded-full">
            <img src={ScanIcon} alt="Scan" />
            Scan
          </button>
        </Link>

        {/* Results Tab */}
        <Link
          to="/results"
          className="flex flex-col items-center"
          onClick={handleResultsTabClick} // Attach event handler for Results tab
        >
          <img
            src={isResults ? ResultsIconSelected : ResultsIcon}
            alt="Results"
          />
          <span
            className={`text-sm-regular text-light_bg_primary ${
              isResults ? "opacity-85" : "opacity-45"
            }`}
          >
            Results
          </span>
        </Link>
      </div>
    </nav>
  );
};

export default BottomNav;
