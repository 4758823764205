import React from "react";
import ReactDOM from "react-dom/client";
import { initAmplitude, setAmplitudeUserDevice } from "./utils/amplitude";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { v4 as uuidv4 } from "uuid";

// Initialize Amplitude and Device ID

const getInstallationToken = () => {
  let token = localStorage.getItem("installationToken");
  if (!token) {
    token = uuidv4(); // Generate a new UUID if it doesn't exist
    localStorage.setItem("installationToken", token);
  }
  return token;
};

initAmplitude();

const devicetoken = getInstallationToken();
setAmplitudeUserDevice(devicetoken);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
