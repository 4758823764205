import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Check from '../assets/Check.png';
import { useNavigate } from 'react-router-dom';
import CtaButton from './CtaButton';
import Whatsapp from './Modals/Whatsapp';

const DEV_MODE = false; // Set this to true to disable the 24-hour restriction during testing

const Tasks = ({ tasks }) => {
  const [scanAllowed, setScanAllowed] = useState(DEV_MODE);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false); // State to track phone verification status
  const [whatsappOpen, setWhatsappOpen] = useState(false); // State to handle Whatsapp modal visibility
  const navigate = useNavigate();

  const userId = localStorage.getItem('userId');

  const checkScanAvailability = async () => {
    if (DEV_MODE) {
      setScanAllowed(true);
      return true;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`
      );
      const scans = response.data;

      if (scans && scans.length > 0) {
        const latestScan = scans[scans.length - 1];

        const latestScanTimeIST = new Date(latestScan.scan_completion_time);
        const currentTimeIST = new Date(); // Assuming client's clock is in IST

        const next6AM = new Date(latestScanTimeIST);
        next6AM.setHours(6, 0, 0, 0);

        if (next6AM <= latestScanTimeIST) {
          // If the latest scan was at or after 6 AM, set next6AM to 6 AM on the next day
          next6AM.setDate(next6AM.getDate() + 1);
        }

        if (currentTimeIST >= next6AM) {
          // Allow scan
          setScanAllowed(false);
          return false;
        } else {
          setScanAllowed(true);
          return true;
        }
      }
      setScanAllowed(false);
      return true;
    } catch (error) {
      console.error('Error fetching scans:', error);
      setScanAllowed(false);
      return false;
    }
  };

  const checkPhoneVerification = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}`
      );
      const user = response.data;

      setIsPhoneVerified(user.numberVerified); // Set phone verification status
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await checkScanAvailability();
      await checkPhoneVerification();
    };

    if (!DEV_MODE) {
      fetchData();
    } else {
      setScanAllowed(true);
    }
  }, []);

  const handleCheckboxClick = (e) => {
    e.preventDefault(); // Prevent the default checkbox behavior
    if (!scanAllowed) {
      navigate('/intro-scan/scan');
    }
  };

  const handleCtaClick = () => {
    setWhatsappOpen(true); // Open the Whatsapp modal when the CtaButton is clicked
  };

  const handleCloseWhatsapp = () => {
    setWhatsappOpen(false); // Close the Whatsapp modal
  };

  const tasksToShow = [...tasks];

  if (!isPhoneVerified) {
    // Add the third task if the phone number is not verified
    tasksToShow.push({
      id: 'verify-phone',
      task: 'Get notified for your next scan',
    });
  }

  return (
    <>
      <div className="p-4 bg-base_white rounded-[16px] border-[1px] border-[#E5E7EB]">
        {tasksToShow.map((task, index) => (
          <div key={task.id}>
            <div className="flex items-center justify-between">
              <div className="truncate font-inter text-[14px] leading-[21px] mr-4 text-light_bg_secondary text-ellipsis flex-grow">
                {task.task}
              </div>
              <div className="flex-shrink-0">
                {task.id !== 'verify-phone' && ( // Only render checkbox for other tasks
                  <label
                    className="relative flex items-center rounded-full cursor-pointer"
                    htmlFor={`check-${task.id}`}
                    onClick={handleCheckboxClick}
                  >
                    <input
                      type="checkbox"
                      checked={scanAllowed}
                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#111927] checked:bg-[#111927] checked:before:bg-gray-900 hover:before:opacity-10"
                      id={`check-${task.id}`}
                      readOnly
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                )}
              </div>
            </div>
            {index !== tasksToShow.length - 1 && (
              <hr className="text-gray_neutral_100 mt-2 mb-2 mr-5" />
            )}
          </div>
        ))}

        {!isPhoneVerified && ( // Render the CtaButton below the last task if phone number is not verified
          <div className="mt-4" onClick={handleCtaClick}>
            <CtaButton
              ButtonText="Get reminder daily to scan"
              className="py-2 px-4 bg-blue-600 text-white rounded-md w-full"
            />
          </div>
        )}

        {scanAllowed && tasksToShow.length === tasks.length && ( // Only show this if all tasks are completed
          <div className="flex items-center">
            <div className="max-h-[36px] mt-3 max-w-[36px]">
              <img
                src={Check}
                alt="Check"
                className="min-h-[36px] min-w-[36px]"
              />
            </div>
            <div className="mt-3 ml-2 font-inter text-[14px] font-normal leading-[21px] text-[#111927]">
              Great! You have completed today's tasks, see you tomorrow.
            </div>
          </div>
        )}
      </div>

      {/* Render the Whatsapp modal */}
      {whatsappOpen && (
        <Whatsapp
          isOpen={whatsappOpen}
          onClose={handleCloseWhatsapp}
          userId={userId}
        />
      )}
    </>
  );
};

export default Tasks;
