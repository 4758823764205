import React, { useEffect, useState } from 'react';
import PreOnboarding from '../../assets/PreOnboarding.svg';
import JourneyLayout from '../../components/JourneyLayout';
import Tasks from '../../components/Tasks';
import ProfilerJourney from '../../components/ProfilerJourney';
import SignatureJourney from '../../components/SignatureJourney';
import BlogCard from '../../components/BlogCard';
import Blog1 from '../../assets/Blog1.png';
import Blog2 from '../../assets/Blog2.png';
import Blog3 from '../../assets/Blog3.png';
import HealthFacts from '../../components/HealthFacts';
import Refer from '../../components/Refer';
import BottomNav from '../../components/BottomNav';
import { Link } from 'react-router-dom';
import Loader from '../../utils/Loader';
import facts from '../../components/health_facts.json';
import { sendAmplitudeData } from '../../utils/amplitude';
import axios from 'axios';

const Home = () => {
  const [userName, setUserName] = useState('');
  const [showTask, setShowTask] = useState(false);
  const [loading, setLoading] = useState(true);
  const [day, setDay] = useState(null); // No default, will set it after fetching

  // Define completedSteps logic based on the Day value
  let completedSteps = 0;
  if (day === 2) {
    completedSteps = 1;
  } else if (day === 3) {
    completedSteps = 2;
  } else if (day === 4) {
    completedSteps = 3;
  } else if (day === 5) {
    completedSteps = 4;
  } else if (day > 6) {
    completedSteps = 0;
  }

  // Fetch the latest scans if the day is not available in local storage
  const fetchScansAndUpdateDay = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/latest/${userId}`
      );
      const latestScan = response.data;

      if (latestScan) {
        // Get the Day value from the latest scan
        const dayFromScan = latestScan.day || 1;

        console.log('Latest Scan:', latestScan);

        // Update localStorage with the latest day value only if day >= 2 (day 1 is completed)
        if (dayFromScan >= 2) {
          localStorage.setItem('Day', dayFromScan);
        }

        // Set the day in the component state
        setDay(dayFromScan);
      } else {
        console.log('No scans found for the user.');
      }
    } catch (error) {
      console.error('Error fetching scan data:', error);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    // Check if Day is available in localStorage
    const storedDay = localStorage.getItem('Day');

    if (storedDay) {
      // If Day exists in localStorage, use it
      setDay(Number(storedDay));
    } else if (userId) {
      // If Day is not in localStorage, fetch it from the API
      fetchScansAndUpdateDay(userId);
    }

    // Fetch user data and set the username
    if (userId) {
      fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.firstname) {
            setUserName(data.firstname);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (day >= 2) {
      setShowTask(true);
    }
  }, [day]);

  const tasks = [
    {
      id: 1,
      task: "Complete today's scan ",
    },
    {
      id: 2,
      task: 'Check upcoming questions',
    },
  ];

  const handleProfilerJourneyClick = () => {
    sendAmplitudeData('OPENED_PROFILER_JOURNEY');
  };

  // Event handlers for each article (blog) Amplitude
  const handleBlog1Click = () => {
    sendAmplitudeData('TAP_BLOG_CARD', {
      article: 'Learn about cardio fitness',
    });
  };

  const handleBlog2Click = () => {
    sendAmplitudeData('TAP_BLOG_CARD', {
      article: 'Lower your resting heart rate',
    });
  };

  const handleBlog3Click = () => {
    sendAmplitudeData('TAP_BLOG_CARD', {
      article: 'Heart rate and anxiety',
    });
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${PreOnboarding})`,
          backgroundRepeat: 'repeat-x',
          position: 'relative',
          backgroundSize: 'contain',
        }}
      >
        <JourneyLayout>
          <div className="flex gap-x-2 justify-between items-start mt-4">
            <h1 className="text-light_bg_primary display-title ">
              Good morning, {userName}
            </h1>
          </div>
          {showTask && (
            <div className="flex-col mt-10">
              <h1 className="text-light_bg_primary display-title-medium mb-4">
                Today's tasks
              </h1>
              <Tasks tasks={tasks} />
            </div>
          )}

          <h1 className="display-title-medium mt-4 text-light_bg_primary mb-2 text-opacity-95">
            Journeys
          </h1>
          <Link
            to="/home/profiler-journey"
            onClick={handleProfilerJourneyClick}
          >
            <ProfilerJourney completedSteps={completedSteps} />
          </Link>
          <SignatureJourney completedSteps={0} />
          <h1 className="display-title-medium text-light_bg_primary opacity-95 mt-10 mb-4">
            Explore wellness
          </h1>
          {/* Blog 1 */}
          <Link to="/home/blog1" onClick={handleBlog1Click}>
            <BlogCard
              time={10}
              title={'Learn about cardio fitness'}
              description={
                'How it’s measured, why it matters, and how to improve yours.'
              }
              image={Blog1}
            />
          </Link>

          {/* Blog 2 */}
          <Link to="/home/blog2" onClick={handleBlog2Click}>
            <BlogCard
              time={1}
              title={'Lower your resting heart rate'}
              description={
                'How it’s measured, why it matters, and how to improve yours.'
              }
              image={Blog2}
            />
          </Link>

          {/* Blog 3 */}
          <Link to="/home/blog3" onClick={handleBlog3Click}>
            <BlogCard
              time={1}
              title={'Heart rate and anxiety'}
              description={
                'How it’s measured, why it matters, and how to improve yours.'
              }
              image={Blog3}
            />
          </Link>
          <h2 className="text-light_bg_primary display-title-medium mb-4 mt-10">
            Health facts
          </h2>
          <div className="mb-8">
            <HealthFacts facts={facts} day={day} />
          </div>
        </JourneyLayout>
        <Refer />
        <BottomNav />
      </div>
    </>
  );
};

export default Home;
