import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import HeartRate from '../../components/HeartRate';
import RespirationRate from '../../components/RespirationRate';
import OxygenSaturation from '../../components/OxygenSaturation';
import Vitalfirstcta from '../../assets/Vitalfirstcta.png';
import axios from 'axios';
import LearnMoreModal from '../../components/LearnMoreModal';
import { Link } from 'react-router-dom';
import Loader from '../../utils/Loader';
import { sendAmplitudeData } from '../../utils/amplitude'; // Import Amplitude utility
import LockedCard from '../../components/LockedCard';

const VitalResultsFirst = () => {
  const [lastScanResult, setLastScanResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Retrieve user ID from localStorage
  const userId = localStorage.getItem('userId');

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    const fetchScanDetails = async () => {
      if (!userId) {
        setError('User ID not found');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data && response.data.length > 0) {
          const latestScan = response.data[response.data.length - 1];
          console.log('Latest scan:', latestScan);
          setLastScanResult(latestScan);
        } else {
          setError('No scan results found');
        }
      } catch (error) {
        console.error('Error fetching scan details:', error);
        setError('Failed to fetch scan details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchScanDetails();
  }, [userId]);

  // Log event when Heart Rate card is clicked
  const handleHeartRateClick = () => {
    sendAmplitudeData('TAP_HEART_RATE_CARD');
  };

  // Log event when Respiration Rate card is clicked
  const handleRespirationRateClick = () => {
    sendAmplitudeData('TAP_RESPIRATION_RATE_CARD');
  };

  // Log event when Oxygen Saturation card is clicked
  const handleOxygenSaturationClick = () => {
    sendAmplitudeData('TAP_OXYGEN_SATURATION_CARD');
  };

  // Log event when the Create Account button is clicked
  const handleCreateAccountClick = () => {
    sendAmplitudeData('TAP_CREATE_ACCOUNT');
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-gray_neutral_50">
      <Layout>
        <h1 className="display-title mt-3 text-light_bg_primary">
          Your first scan
        </h1>
        <p className="text-sm-regular mt-2 text-light_bg_secondary mb-4">
          These are your body's vital signs from the finger scan. To
          understand them better{' '}
          <span className="text-primary_500 underline font-inter text-[14px] font-[500] leading-5">
            <button onClick={handleOpenModal}>learn more</button>
          </span>
        </p>
        {lastScanResult && (
          <>
            {/* Heart Rate Card */}
            <div onClick={handleHeartRateClick}>
              <HeartRate
                heartRate={lastScanResult.vitals.heart_rate}
                label="Heart rate"
              />
            </div>

            {/* Respiration Rate Card */}
            <div className="flex justify-evenly items-stretch w-full gap-3">
              <div className="flex-1 min-w-0">
                {' '}
                <div onClick={handleRespirationRateClick}>
                  <RespirationRate
                    respirationRate={lastScanResult.vitals.resp_rate}
                  />
                </div>
              </div>

              {/* Oxygen Saturation Card */}
              <div className="flex-1 min-w-0">
                <div onClick={handleOxygenSaturationClick}>
                  <OxygenSaturation
                    oxygenSaturation={
                      lastScanResult.vitals.oxy_sat_prcnt
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-evenly items-stretch w-full gap-3">
              <div className="flex-1 min-w-0">
                {' '}
                <div>
                  <LockedCard CardName="Heart age" Icon="1" />
                </div>
              </div>

              {/* Oxygen Saturation Card */}
              <div className="flex-1 min-w-0">
                <div>
                  <LockedCard CardName="Heart energy" Icon="2" />
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>

      {/* Create Account Section */}
      <div className="relative">
        <img src={Vitalfirstcta} alt="register" className="w-full" />
        <Link to="/create-account">
          <button
            className="bg-primary_500 heading-small w-[90%] md:max-w-[361px] text-[#FCFCFD] py-3 px-5 rounded-[8px] absolute bottom-8 left-1/2 transform -translate-x-1/2"
            onClick={handleCreateAccountClick}
          >
            Create your account
          </button>
        </Link>
      </div>

      {/* Learn More Modal */}
      <LearnMoreModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default VitalResultsFirst;
