import React, { useState, useEffect } from 'react';
import chevronLeft from '../../assets/chevronLeft.svg';
import JourneyLayout from '../../components/JourneyLayout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../utils/Loader';

const mockQuestions = {
  day1: [
    {
      questionNo: 1,
      question: 'Do you smoke?',
      options: ['Currently a smoker', 'An Ex-smoker', 'A non smoker'],
      subQuestionTrigger: 0,
      subQuestion:
        'Indicate the number of cigarettes you smoke per day',
      subOptions: [
        '10 cigarettes or less',
        '11-20 cigarettes',
        'More than 20 cigarettes',
      ],
      _id: '1',
    },
    {
      questionNo: 2,
      question: 'Do you have diabetes (high sugar)?',
      options: [
        'I am diabetic since Childhood',
        'Diabetes developed in Adulthood',
        'None',
      ],
      subQuestionTrigger: [0, 1],
      subQuestion: 'Are you Insulin dependent',
      subOptions: ['yes', 'no'],
      _id: '2',
    },
    {
      questionNo: 3,
      question:
        'Has anyone in your family suffered heart-related chest pain before reaching the age of 60?',
      options: ['Parent', 'Sibling', 'None'],
      _id: '3',
    },
  ],
  day2: [
    {
      questionNo: 1,
      question:
        'Has anyone in your family had a heart attack before turning 60 years of age?',
      options: ['Parent', 'Sibling', 'None'],
      _id: '4',
    },
    {
      questionNo: 2,
      question:
        'Do you get severe headaches (also called as Migraines)?',
      options: ['yes', 'no'],
      _id: '5',
    },
  ],
  day3: [
    {
      questionNo: 1,
      question:
        'Have you been diagnosed with any psychological disorder like Depression or bipolar disorder?',
      options: ['yes', 'no'],
      _id: '6',
    },
    {
      questionNo: 2,
      question:
        'Do you have or are you being treated for High Blood Pressure?',
      options: ['yes', 'no', 'not sure'],
      _id: '7',
    },
    {
      questionNo: 3,
      question: 'Do you engage in regular physical activity?',
      options: ['yes', 'no'],
      subQuestionTrigger: 0,
      subQuestion: 'How often do you exercise per week?',
      subOptions: ['1-2 times', '3-4 times', '5 or more times'],
      _id: '8',
    },
    {
      questionNo: 4,
      question: 'Do you have a balanced diet?',
      options: ['yes', 'no'],
      subQuestionTrigger: 0,
      subQuestion:
        'How many servings of fruits and vegetables do you have daily?',
      subOptions: [
        '1-2 servings',
        '3-4 servings',
        '5 or more servings',
      ],
      _id: '9',
    },
    {
      questionNo: 5,
      question:
        'Do you experience chest pain during physical activity?',
      options: ['yes', 'no'],
      _id: '10',
    },
    {
      questionNo: 6,
      question: 'Have you ever been diagnosed with high cholesterol?',
      options: ['yes', 'no'],
      _id: '11',
    },
  ],
  day4: [
    {
      questionNo: 1,
      question: 'What is your age group?',
      options: [
        'Below 35 years',
        '35-49 years',
        '50 years and above',
      ],
      _id: '12',
    },
    {
      questionNo: 2,
      question: 'What is your waist circumference?',
      options: [
        'Less than 90 cm (men) or 80 cm (women)',
        '90-99 cm (men) or 80-89 cm (women)',
        '100 cm and above (men) or 90 cm and above (women)',
      ],
      _id: '13',
    },
    {
      questionNo: 3,
      question: 'What is your physical activity level?',
      options: [
        'Regular exercise or strenuous work',
        'Occasional exercise or moderate work',
        'No exercise and sedentary work',
      ],
      _id: '14',
    },
    {
      questionNo: 4,
      question: 'Do you have a family history of diabetes?',
      options: [
        'No family history',
        'One parent has diabetes',
        'Both parents have diabetes',
      ],
      _id: '15',
    },
    {
      questionNo: 5,
      question: 'Do you have self-reported diabetes?',
      options: ['Yes', 'No'],
      _id: '16',
    },
  ],
  day5: [
    {
      questionNo: 1,
      question:
        'Do you snore loudly (louder than talking or loud enough to be heard through closed doors)?',
      options: ['Yes', 'No'],
      _id: '17',
    },
    {
      questionNo: 2,
      question:
        'Do you often feel tired, fatigued, or sleepy during daytime?',
      options: ['Yes', 'No'],
      _id: '18',
    },
    {
      questionNo: 3,
      question:
        'Has anyone observed you stop breathing during your sleep?',
      options: ['Yes', 'No'],
      _id: '19',
    },
    {
      questionNo: 4,
      question:
        'Do you have or are you being treated for high blood pressure?',
      options: ['Yes', 'No'],
      _id: '20',
    },
    {
      questionNo: 5,
      question: 'Is your BMI more than 35 kg/m²?',
      options: ['Yes', 'No'],
      _id: '21',
    },
    {
      questionNo: 6,
      question: 'Are you older than 50 years?',
      options: ['Yes', 'No'],
      _id: '22',
    },
    {
      questionNo: 7,
      question: 'Is your neck circumference greater than 40 cm?',
      options: ['Yes', 'No'],
      _id: '23',
    },
    {
      questionNo: 8,
      question: 'Are you male?',
      options: ['Yes', 'No'],
      _id: '24',
    },
  ],
};

const Questionnaire = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showSubQuestion, setShowSubQuestion] = useState(false);
  const [answers, setAnswers] = useState({});
  const [day, setDay] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);

  const userId = localStorage.getItem('userId');

  const updateDay = async () => {
    let day = localStorage.getItem('Day');
    day = parseInt(day) + 1;
    localStorage.setItem('Day', day);

    // Fetch the latest scan and update the Day value
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/latest/${userId}`
      );
      const latestScan = response.data;
      console.log('Latest scan:', latestScan);

      if (latestScan) {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${latestScan._id}`,
          {
            day: day,
          }
        );
      } else {
        console.error('No latest scan found.');
      }
    } catch (error) {
      console.error(
        'Error updating Day value in the latest scan:',
        error
      );
    }
  };

  useEffect(() => {
    const determineCurrentDay = async () => {
      let currentDay = localStorage.getItem('Day');
      setDay(currentDay);
      setQuestions(mockQuestions[`day${currentDay}`] || []);
    };

    determineCurrentDay();
  }, [userId]);

  useEffect(() => {
    const savedAnswers = localStorage.getItem('questionnaireAnswers');
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, []);

  useEffect(() => {
    const day = parseInt(localStorage.getItem('Day'), 10);
    setDay(day);
  }, [day]);

  useEffect(() => {
    localStorage.setItem(
      'questionnaireAnswers',
      JSON.stringify(answers)
    );
  }, [answers]);

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswer = (answer, optionIndex) => {
    setSelectedOption(optionIndex);
    setAnswers({ ...answers, [currentQuestionIndex]: { answer } });

    setTimeout(() => {
      if (currentQuestion.subQuestionTrigger !== undefined) {
        if (Array.isArray(currentQuestion.subQuestionTrigger)) {
          if (
            currentQuestion.subQuestionTrigger.includes(optionIndex)
          ) {
            setShowSubQuestion(true);
            setSelectedOption(null);
            return;
          }
        } else if (
          currentQuestion.subQuestionTrigger === optionIndex
        ) {
          setShowSubQuestion(true);
          setSelectedOption(null);
          return;
        }
      }

      nextQuestion();
    }, 400);
  };

  const handleSubAnswer = (subAnswer, optionIndex) => {
    setSelectedSubOption(optionIndex);
    setAnswers({
      ...answers,
      [currentQuestionIndex]: {
        ...answers[currentQuestionIndex],
        subAnswer,
      },
    });
    setTimeout(() => {
      nextQuestion();
    }, 400);
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setSelectedOption(null);
      setSelectedSubOption(null);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowSubQuestion(false);
    } else {
      submitAnswers();
      setTimeout(() => {
        setSelectedOption(null);
        setSelectedOption(null);
      }, 5000);
    }
  };

  const previousQuestion = () => {
    setSelectedOption(null);
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShowSubQuestion(false);
    }
  };

  const submitAnswers = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      console.log('Submitting answers:', answers);
      const requestBody = {
        questions: questions.map((q, index) => ({
          questionNo: q.questionNo,
          question: q.question,
          answer: answers[index]?.answer || '',
          subAnswer: answers[index]?.subAnswer || '',
        })),
      };

      console.log('answers submitted');
      console.log(typeof day);

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/questionnaires/${userId}/day/${day}`,
        requestBody
      );

      console.log('before patch');
      console.log(day);

      if (day === 1) {
        console.log('sending patch');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`
        );
        const healthData = response.data;

        // Sort the scans by entry_time to find the most recent one
        const sortedScans = healthData.sort(
          (a, b) => new Date(b.entry_time) - new Date(a.entry_time)
        );
        const mostRecentScan = sortedScans[0];

        const HR = mostRecentScan.vitals.heart_rate;

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`,
          {
            HR: HR,
          }
        );
      }

      if (day === 2) {
        console.log('sending patch');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`
        );
        const healthData = response.data;

        // Sort the scans by entry_time to find the most recent one
        const sortedScans = healthData.sort(
          (a, b) => new Date(b.entry_time) - new Date(a.entry_time)
        );
        const mostRecentScan = sortedScans[0];

        const prqValue = mostRecentScan.metadata.cardiovascular.prq;

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/journeys/${userId}`,
          {
            prq: prqValue,
          }
        );
      }

      if (day === 3) {
        console.log('sending patch');
        await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/cardiovascular-age/${userId}`
        );
      }

      if (day === 4) {
        console.log('sending patch');
        await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/day4-scores/${userId}`
        );
      }

      if (day === 5) {
        console.log('sending patch');
        await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/day5-scores/${userId}`
        );
      }

      if (day === 5) {
        navigate('/results');
      } else if (day === 1) {
        navigate('/day1-result');
      } else {
        navigate(`/profilerresult`);
      }
      updateDay();

      localStorage.setItem('profilerCompleted', 'false');
    } catch (error) {
      console.error('Error submitting answers:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <>
      <h2 className="heading-large text-center text-light_bg_primary opacity-95 m-5">
        Health assessment
      </h2>
      <JourneyLayout>
        <div className="max-w-md mx-auto">
          <div className="mb-4">
            <div className="flex space-x-1">
              {questions.map((_, index) => (
                <div
                  key={index}
                  className={`h-1 w-full rounded-[4px] ${
                    index < currentQuestionIndex
                      ? ' bg-primary_500'
                      : ' bg-[#E5E7EB]'
                  }`}
                />
              ))}
            </div>
          </div>
          <p className="text-sm-regular text-light_bg_secondary mt-6">
            Help us in assessing your current health and likelihood of
            developing heart related problems
          </p>
          <div className="mt-16 shadow-question_card bg-gray_neutral_50 pt-5 px-5 pb-32 rounded-[16px]">
            {currentQuestion && (
              <>
                <h3 className="text-light_bg_primary text-md-regular mb-6">
                  {showSubQuestion
                    ? currentQuestion.subQuestion
                    : currentQuestion.question}
                </h3>
                {!showSubQuestion
                  ? currentQuestion.options.map((option, index) => (
                      <button
                        key={index}
                        className={`w-full text-md-regular text-gray_600 text-left p-3 mb-4 bg-white rounded-[8px] ${
                          selectedOption === index
                            ? 'border-secondary_500 border-[1px] shadow-user_details_input'
                            : 'border-[#E5E7EB] border-[1px]'
                        }`}
                        onClick={() => handleAnswer(option, index)}
                      >
                        {option}
                      </button>
                    ))
                  : currentQuestion.subOptions.map(
                      (option, index) => (
                        <button
                          key={index}
                          className={`w-full text-md-regular text-gray_600 text-left p-3 mb-4 bg-white rounded-[8px] ${
                            selectedSubOption === index
                              ? 'border-secondary_500 border-[1px] shadow-user_details_input'
                              : 'border-[#E5E7EB] border-[1px]'
                          }`}
                          onClick={() =>
                            handleSubAnswer(option, index)
                          }
                        >
                          {option}
                        </button>
                      )
                    )}
              </>
            )}
          </div>
          <div className="fixed bottom-0 left-0 right-0 p-4 bg-white">
            <div className="max-w-md mx-auto flex items-center">
              {currentQuestionIndex > 0 ? (
                <>
                  <button
                    className="flex items-center justify-center p-2 bg-gray_neutral_100 shadow-xs rounded-[8px] mr-4"
                    onClick={previousQuestion}
                  >
                    <img
                      src={chevronLeft}
                      alt="back"
                      className="w-8 h-8 opacity-50"
                    />
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </JourneyLayout>
    </>
  );
};

export default Questionnaire;
