import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import { useLocation } from 'react-router-dom';
import introScanimage from '../../assets/IntroScanImage.png';
import heartIcon from '../../assets/heartIcon.png';
import moodIcon from '../../assets/moodIcon.png';
import CtaButton from '../../components/CtaButton';

const IntroScan = () => {
  // eslint-disable-next-line no-unused-vars
  const [isSliding, setIsSliding] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.animate) {
      const timer = setTimeout(() => setIsSliding(false), 50);
      return () => clearTimeout(timer);
    } else {
      setIsSliding(false);
    }
  }, [location.state]);

  return (
    <div className="w-screen h-screen overflow-hidden flex flex-col">
      <div className="relative">
        <img src={introScanimage} alt="Scan" className="w-full" />
        <h1 className="absolute bottom-2 left-5 mr-5 transform font-inter text-[28px] font-normal text-[#1D2939] leading-[33.6px] tracking-[-0.28px]">
          Assess your heart health with your first scan
        </h1>
      </div>
      <Layout className="flex-grow flex flex-col justify-between">
        <div className="flex-col items-center mt-4">
          <div className="flex items-start">
            <img
              src={heartIcon}
              alt="heart"
              className="shadow-icon mr-4 max-h-12 max-w-12"
            />
            <div className="flex-col">
              <h3 className="heading-small text-light_bg_primary mb-1">
                Scan your finger tip via camera
              </h3>
              <h4 className="text-sm-regular text-light_bg_secondary">
                Get your heart rate and other vitals by scanning your
                finger tip just like a smart watch or oximeter
              </h4>
            </div>
          </div>

          <div className="flex items-start mt-4">
            <img
              src={moodIcon}
              alt="mood"
              className="shadow-icon mr-4 max-h-12 max-w-12"
            />
            <div className="flex-col">
              <h3 className="heading-small text-light_bg_primary mb-1">
                Log your mood
              </h3>
              <h4 className="text-sm-regular text-light_bg_secondary">
                Share how you feel and track your mood. This helps us
                better analyse your heart health
              </h4>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4">
          <p className="font-inter text-[12px] font-normal leading-[18px] text-gray_neutral_400 text-center px-8 mb-3">
            By continuing, you agree to our{' '}
            <span className="text-gray_neutral_800">
              Terms of Service
            </span>{' '}
            and allow us to access your device's camera
          </p>
          <div>
            <CtaButton
              ButtonText="Start Scan"
              LinkTo="/intro-scan/scan"
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default IntroScan;
